var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"background"},[_c('transition',{attrs:{"name":"fade"}},[_c('img',{key:_vm.images[_vm.currentSlide].src,attrs:{"src":_vm.images[_vm.currentSlide].src,"alt":_vm.images[_vm.currentSlide].alt}})]),_vm._v(" "),_c('div',{staticClass:"gradient"})],1),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7"},[_c('div',{staticClass:"typer-wrapper"},[_c('vue-typer',{attrs:{"type-delay":50,"erase-delay":25,"erase-style":"select-back","caret-animation":"phase","text":[
                            // Linebreak for example
                            "Powering \nUnforgettable \nExperiences",
                            "Streaming\nCutting Edge\nBroadcasts",
                            "Redefining\nEsports\nEntertainment",
                            "Delivering\nMonumental\nProductions",
                            "Turning\nPlayers\nInto Stars"
                        ]},on:{"erased":_vm.nextSlide}})],1),_vm._v(" "),_c('div',{staticClass:"buttons"},[_c('slant-button',{staticClass:"first",attrs:{"text":"Build With Us","name":"contact"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }