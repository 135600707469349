<template>
    <section>
        <div class="background">
            <transition name="fade">
                <img :src="images[currentSlide].src" :alt="images[currentSlide].alt" :key="images[currentSlide].src">
            </transition>
            <div class="gradient"></div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xl-7">
                    <div class="typer-wrapper">
                        <vue-typer
                            :type-delay='50'
                            :erase-delay='25'
                            erase-style='select-back'
                            caret-animation='phase'
                            :text='[
                                // Linebreak for example
                                "Powering \nUnforgettable \nExperiences",
                                "Streaming\nCutting Edge\nBroadcasts",
                                "Redefining\nEsports\nEntertainment",
                                "Delivering\nMonumental\nProductions",
                                "Turning\nPlayers\nInto Stars"
                            ]'
                            @erased='nextSlide'>
                        </vue-typer>
                    </div>
                    <div class="buttons">
                        <slant-button class="first" text="Build With Us" name="contact" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            currentSlide: 0,
            images: [
                {
                    'src':'https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/slides/trophy.jpg',
                    'alt':'Fortnite Live Event'
                },
                {
                    'src':'https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/slides/broadcasts.jpg',
                    'alt':'Live Event, Behind the scenes at Esports Engine'
                },
                {
                    'src':'https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/slides/entertainment.jpg',
                    'alt':'Clash Royale World Finals'
                },
                {
                    'src':'https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/slides/monumental.jpg',
                    'alt':'Call of Duty World League - Champs 2019'
                },
                {
                    'src':'https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/slides/stars.jpg',
                    'alt':'Overwatch League Walkout - Ben Persell'
                },
            ]
        }
    },
    methods: {
        nextSlide: function(){
            if(this.currentSlide < Object.keys(this.images).length-1){
                this.currentSlide++;
            }else{
                this.currentSlide = 0;
            }
        }
    },
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        background: $chalkboard;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 95vh;
        position: relative;
        .background{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            img{
                transition: opacity 0.5s;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .gradient{
                position: absolute;
                top: 0;
                width: 60%;
                height: 100%;
                background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            }
        }
        .typer-wrapper{
            margin-bottom: 1rem;
            height: 18rem;
            color: $white;
        }
        .buttons{
            display: flex;
            .first{
                margin-right: 2rem;
            }
        }
    }
    .scroll-prompt{
        position: absolute;
        bottom: 0;
        i{
            font-size: 2rem;
            color: $white;
        }
    }
    .vue-typer{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 5.25rem;
        line-height: 5.25rem;
    }
    @include media-breakpoint-down(md) {
        section{
            justify-content: flex-end;
            padding-bottom: 4rem;
            .typer-wrapper{
                height: 12rem;
                .vue-typer{
                    font-size: 4rem;
                    line-height: 4rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        section{
            justify-content: flex-end;
            .buttons{
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .first{
                    margin-right: unset;
                    margin-bottom: 1rem;
                }
            }
            .typer-wrapper{
                height: 8rem;
                .vue-typer{
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                }
            }
        }
    }
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
</style>
