<template>
    <section id="info">
        <img class="background" src="https://d2n34pb7vz7boc.cloudfront.net/images/elements/about-slices.jpg">
        <div class="container">
            <div class="row justify-content-between split">
                <div class="col-xl-5 text">
                    <p>Esports Engine was founded in October 2019 by former Major League Gaming execs  - Adam Apicella and Ryan Thompson both of whom have long storied histories within the industry. They’re just part of the elite team at Esports Engine that has defined innovation for esports globally through the creation and execution of over 500 live events from 2003-2019.</p>
                </div>
                <div class="col-xl-6">
                    <img src="https://d2n34pb7vz7boc.cloudfront.net/images/about/ohioteam.jpg" class="ohio" alt="Esports Engine Ohio Team">
                </div>
            </div>
            <div class="row justify-content-between split middle">
                <div class="col-xl-6">
                    <img class="invert" src="https://d2n34pb7vz7boc.cloudfront.net/images/about/burbankteam.jpg" alt="Esports Engine Burbank Team">
                </div>
                <div class="col-xl-5 text">
                    <p>Meanwhile, in California, NGE (Next Generation Esports), an integral part of today’s EE, was founded in 2015. NGE’s focus was providing turnkey white label esports infrastructure & production services. NGE forged strong relationships through their passion for the industry and most prominent core value “Love Games.” NGE was acquired in 2019 by Vindex. In 2021, Esports Engine and NGE in Burbank integrated to form the powerhouse Esports Engine we have today.</p>
                </div>
            </div>
            <div class="row justify-content-between split bottom">
                <div class="col-xl-5 text">
                    <p>Esports Engine is a turnkey esports solutions company working with gaming publishers, rightsholders, brands, and teams to provide production, broadcast, tournament, and program design. We bring 20+ years of experience, relationships, a deep understanding of consumer culture, and production expertise to manage publisher’s esports operations top-down through a holistic solution that meets the individualized needs of game publishers, competitors, and fans.</p>
                    <slant-button class="top" text="Work With Us" name="contact" />
                </div>
                <div class="col-xl-6">
                    <div class="stats">
                        <div class="col-4 stat" v-for="stat, i in stats" :key="i">
                            <h3>{{ stat.value }}</h3>
                            <p>{{ stat.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            stats: [
                {
                    name: 'Turnkey Esports Events',
                    value: '500+'
                },
                {
                    name: 'Broadcast hours in 2020 Alone',
                    value: '2,220+'
                },
                {
                    name: 'Tournaments Executed',
                    value: '770+'
                },
                {
                    name: 'Employees and growing',
                    value: '200+'
                },
                {
                    name: 'Different Games Operated',
                    value: '75+'
                },
                {
                    name: 'Studio spaces and growing',
                    value: '8+'
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
#info{
    padding-top: 3rem;
    margin-bottom: 5rem;
    position: relative;
    img.background{
        position: absolute;
        top: 5rem;
        left: 0;
        width: 100%;
    }
    .split{
        margin-bottom: 5rem;
        &:last-child{
            margin-bottom: unset;
        }
        .text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        img{
            width: 100%;
            height: 22rem;
            object-fit: cover;
            clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
            -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
        }
        img.invert{
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
            -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        }
        .stats{
            display: flex;
            flex-wrap: wrap;
            .stat{
                margin-bottom: 1rem;
                h3{
                    color: $eered;
                }
                p{
                    font-size: 0.8rem;
                    color: $stone;
                }
            }
        }
        .top{
            margin-top: 1rem;
        }
    }
}
@media screen and (max-width: 1399px) {
    #info{
        padding-top: 2rem;
        margin-bottom: 2rem;
        img.background{
            display: none;
        }
        .split{
            margin-bottom: 4rem;
            .text{
                margin-bottom: 2rem;
                .top{
                    margin-top: unset;
                }
            }
            img{
                clip-path: unset;
                -webkit-clip-path: unset;
                &.ohio{
                    object-position: center bottom;
                }
            }
            img.invert{
                height: 20rem;
                clip-path: unset;
                -webkit-clip-path: unset;
            }
        }
        .split.middle{
            flex-direction: column-reverse;
        }
        .split.bottom{
            flex-direction: column-reverse;
        }
    }
}
@include media-breakpoint-down(sm) {
    #info{
        .split{
            img{
                height: unset;
                object-fit: contain;
                &.invert{
                    height: unset;
                }
            }
        }
    }
}
</style>
