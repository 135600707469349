<template>
    <div class="capability col-md-4 col-sm-6 col-6">
        <div class="dot"></div>
        <p>{{ capability.capability }}</p>
    </div>
</template>

<style lang="scss" scoped>
    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';
    .capability{
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        p{
            font-size: 1rem;
            color: #727F86;
            margin-bottom: unset;
        }
        .dot{
            transform: skew(-15deg);
            width: 0.5rem;
            height: 0.5rem;
            background: #B42A34;
            margin-bottom: 0.6rem;
        }
        @media screen and (max-width: 1399px) {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
    }
</style>

<script>
    export default{
        props: ['capability'],
    }
</script>
