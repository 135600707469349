<template>
    <section :id="studio.slug">
        <div class="container">
            <div class="core">
                <div class="row justify-content-between">
                    <div class="col-12" v-if="studio.header_img">
                        <img :src="studio.header_img" :alt="studio.name">
                    </div>
                    <div class="col-lg-6 title">
                        <h1>{{ studio.name }}</h1>
                    </div>
                    <div class="col-lg-3 address">
                        <p>Location:</p>
                        <p class="location">{{ studio.location }}</p>
                        <CoolLightBox v-if="studio.floorplans?.length" :items="studio.floorplans" :index="index" @close="index = null" :loop="false" :slideshow="false"></CoolLightBox>
                        <slant-button text="View Floorplans" v-on:click.native="index = 0" v-if="studio.floorplans?.length"/>
                    </div>
                </div>
                <div class="row capabilities justify-content-between">
                    <div class="col-lg-3 col-md-6 col-6" v-for="core, i in studio.capabilities" :key="i">
                        <core-capability :core="core" v-if="core.type_id == 1"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-capabilities">
            <div class="container">
            <div class="row justify-content-between">
                <div class="col-xl-6" v-if="studio.capabilities_img">
                    <img :src="studio.capabilities_img" alt="">
                </div>
                <div :class="[studio.capabilities_img ? 'col-xl-6 half' : 'col-xl-12', 'wrapper']">
                    <p>{{ studio.name }}</p>
                    <h3>Capabilities</h3>
                    <div class="list">
                        <template v-for="capability, i in studio.capabilities">
                            <studio-capability v-if="capability.type_id == 2" :key="i" :capability="capability" :maxspace="studio.capabilities_img ? false : true" />
                        </template>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="spaces" v-for="space, i in studio.spaces" :key="i">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-xl-6" v-if="space.image">
                        <img :src="space.image" :alt="space.name">
                    </div>
                    <div class="col-xl-6 half wrapper">
                        <h3>{{ space.name }}</h3>
                        <div class="list">
                            <template v-for="capability in space.capabilities">
                                <studio-capability :capability="capability" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
    props: ['studio'],
    data(){
        return {
            index: null,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .half{
        padding-left: 3rem;
    }
    section{
        padding: 3rem 0;
        .core{
            margin-bottom: 3rem;
            img{
                width: 100%;
                -webkit-clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                margin-bottom: 3rem;
            }
            .title{
                h1{
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            .address{
                button{
                    outline: unset;
                    border: unset;
                }
                .cool-lightbox{
                    background: rgba(25, 42, 51, 0.9) !important;
                    backdrop-filter: blur(16px);
                    -webkit-backdrop-filter: blur(8px);
                }
                >p{
                    font-size: 1.25rem;
                    margin-bottom: 0.5rem;
                    color: $stone;
                    text-transform: uppercase;
                    line-height: 1.25rem;
                    &.location{
                        font-size: 2rem;
                        line-height: 2rem;
                        color: $chalkboard;
                        font-weight: 700;
                        margin-bottom: 2rem;
                    }
                }
            }
            .capabilities{
                margin-top: 4rem;
            }
        }
        .more-capabilities{
            padding-top: 4rem;
            background: linear-gradient(0deg, rgba(229,236,239,0) 30%, rgba(229,236,239,1) 100%);
            img{
                height: 42rem;
                width: 100%;
                object-fit: cover;
                -webkit-clip-path: polygon(100% 0, 100% 75%, 83% 100%, 0 100%, 0 0);
                clip-path: polygon(100% 0, 100% 80%, 83% 100%, 0 100%, 0 0);
            }
            .wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                p{
                    text-transform: uppercase;
                    color: $stone;
                    font-weight: 700;
                    font-size: 0.7rem;
                    line-height: 0.8rem;
                    margin-bottom: 0.5rem;
                }
                h3{
                    margin-bottom: 1rem;
                }
                .list{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
        .spaces{
            margin-top: 3rem;
            .wrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                h3{
                    margin-bottom: 1rem;
                }
                .list{
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            img{
                height: 22rem;
                width: 100%;
                object-fit: cover;
                -webkit-clip-path: polygon(100% 0, 100% 65%, 83% 100%, 0 100%, 0 0);
                clip-path: polygon(100% 0, 100% 65%, 83% 100%, 0 100%, 0 0);
            }
        }
    }
    @media screen and (max-width: 1399px) {
        .half{
            padding: 7.5px;
        }
        section{
            .more-capabilities{
                img{
                    height: 20rem;
                    -webkit-clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                    margin-bottom: 2rem;
                    object-position: center 65% ;
                }
            }
            .spaces{
                img{
                    height: 20rem;
                    -webkit-clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                    clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
                    margin-bottom: 2rem;
                    object-position: center 30% ;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            padding: 1.5rem 0;
            .core{
                margin-bottom: 2rem;
                img{
                    height: 20rem;
                    object-fit: cover;
                }
                .title{
                    margin-bottom: 1.5rem;
                }
                .address{
                    justify-content: flex-start;
                    p{
                        // margin-bottom: 0.5rem;
                        font-size: 1rem;
                        line-height: 1rem;
                    }
                    span{
                        p.location{
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
            .more-capabilities{
                padding-top: 2rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        section{
            .core{
                img{
                    height: 10rem;
                }
            }
            .more-capabilities{
                img{
                    height: 10rem;
                }
            }
            .spaces{
                img{
                    height: 10rem;
                }
            }
        }
    }
</style>
