<template>
    <section id="careers">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="wrapper">
                        <iframe name="resumator-job-frame" id="resumator-job-frame" class="resumator-advanced-widget" src="//esportsengine.applytojob.com/apply/jobs/" width="100%" scrolling="yes" frameborder="0" allowTransparency="true"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
    // mounted(){
    //     function resizeResumatorIframe(height,nojump){if(nojump== 0){window.scrollTo(0,0);}document.getElementById("resumator-job-frame").height = parseInt(height)+20;}
    // }
}
</script>


<style lang="scss" scoped>
    #careers{
        background: #F4F4F4;
        .wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            iframe{
                margin-top: 5rem;
                width: 100%;
                height: calc(100vh - 11.05rem);
            }
            .loader{
                position: absolute;
            }
        }
    }
</style>
