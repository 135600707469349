<template>
    <div class="section-tag">
        <div class="container">
            <div class="wrapper">
                <div class="tag">
                    <slot name="title" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
.section-tag{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    .wrapper{
        display: flex;
        align-items: left;
        justify-content: flex-start;
        .tag{
            position: relative;
            padding: .75rem 1rem .75rem 0;
            background: $stone;
            h4{
                margin-bottom: unset;
                color: $white;
                line-height: 2.375rem;
            }
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                z-index: -1;
                width: 1000%;
                right: calc(100% - 1rem);
                top: 0;
                background: $stone;
            }
            &::after{
                content: '';
                position: absolute;
                right: -1.5rem;
                top: 0;
                height: 100%;
                width: 2rem;
                background: $stone;
                clip-path: polygon(0 0, 100% 0%, 55% 100%, 0% 100%);
            }
        }
    }
}
</style>