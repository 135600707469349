<template>
  <div class="capability">
    <div class="dot"></div>
    <p class="title">{{ core.title }}</p>
    <p>{{ core.value }}</p>
  </div>
</template>

<script>
export default{
    props: ['core'],
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
.capability{
    .dot{
        transform: skew(-15deg);
        width: 0.8rem;
        height: 0.8rem;
        background: #B42A34;
        margin-bottom: 1rem;
    }
    p.title{
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
        color: $chalkboard;
    }
    p{
        font-size: 1rem;
        color: $stone;
    }
}
</style>
