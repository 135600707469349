<template>
    <router-link v-if="link" :to="{ name: link, hash: anchor }">
        <div class="studio">
            <div class="image">
                <div class="gradient"></div>
                <slot name="image"></slot>
            </div>
            <div class="details">
                <slot name="name"></slot>
            </div>
        </div>
    </router-link>

    <div v-else class="studio">
        <div class="image">
            <div class="gradient"></div>
            <slot name="image"></slot>
        </div>
        <div class="details">
            <slot name="name"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['link', 'anchor'],
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';
    

    a{
        &:hover{
            .studio{
                transform: translateY(-5px);
            }
        }
        .studio{
            .image{
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .studio{
        transition: transform 0.2s;
        overflow: hidden;
        position: relative;
        height: 20rem;
        width: 100%;
        -webkit-clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 75%, 92% 100%, 0 100%, 0 0);
        .image{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            .gradient{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background: linear-gradient(0deg, rgba(13,28,36,1) 0%, rgba(13,28,36,0) 50%);
            }
        }
        .details{
            color: $white;
            position: absolute;
            left: 1.4rem;
            bottom: 0.4rem;
        }
    }
    @include media-breakpoint-down(lg) {
        .studio{
            height: 16rem;
        }
    }
    @include media-breakpoint-down(md) {
        .studio{
            &::after{
                right: -11rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .studio{
            height: 12rem;
        }
    }
</style>
