<template>
    <section>
        <img class="bg" src="https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/proven.jpg" alt="">
        <div class="container">
            <div class="row wrapper">
                <div class="col-xl-7 col-lg-10 col-md-10 content">
                    <h2><span>A proven track record of</span> world class experiences</h2>
                    <div class="buttons">
                        <slant-button text="Explore Our Work" name="work" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
    components: { carousel },
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        padding: 8rem 0;
        overflow: hidden;
        position: relative;
        background: #192A33;
        color: #ffffff;
        .owl-stage{
            position: relative;
            z-index: 0;
            img{
                height: 8rem;
                object-fit: contain;
                padding: 2rem;
            }
        }
        .bg{
            position: absolute;
            right: 0;
            top: -6rem;
        }
        .wrapper{
            display: flex;
            align-items: center;
            .content{
                position: relative;
                z-index: 1;
                h2{
                    margin-bottom: 2rem;
                }
                span{
                    font-weight: 300;
                }
                .buttons{
                    display: flex;
                }
            }
        }
    }
    @media (max-width: 1600px) {
        section{
            .bg{
                right: -10rem;
            }
        }
    }
    @media (max-width: 1398px) {
        section{
            .bg{
                right: -20rem;
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            .bg{
                right: -30rem;
                top: -8rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        section{
            .bg{
                height: 36rem;
                top: -5rem;
                right: -15rem;
                opacity: 0.5;
            }
        }
    }
    @media (max-width: 500px) {
        section{
            padding: 6rem 0;
            .bg{
                right: -20rem;
            }
        }
    }
</style>
