<template>
    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <h2>Esports engine has been a part of nearly every <span>major beat in esports history</span></h2>
                </div>
            </div>
            <div class="row timeline">
                <div class="col-4" data-aos="fade-left" data-aos-offset="160" data-aos-duration="300" data-aos-ease="ease-in-quart">
                    <segment>
                        <img slot="logo" src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/mlg.svg" alt="Esports Engine">
                        <p slot="date">2002-2016</p>
                    </segment>
                </div>
                <div class="col-4" data-aos="fade-left" data-aos-offset="220" data-aos-duration="300" data-aos-ease="ease-in-quart">
                    <segment>
                        <img slot="logo" src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/nge.svg" alt="Next Generation Esports">
                        <p slot="date">2015-2020</p>
                    </segment>
                </div>
                <div class="col-4" data-aos="fade-left" data-aos-duration="280" data-aos-offset="280" data-aos-ease="ease-in-quart">
                    <segment>
                        <img slot="logo" src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/esports-engine-dark.svg" alt="Esports Engine">
                        <p slot="date">2019-Present</p>
                    </segment>
                </div>
                <div class="line"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
section{
    background: linear-gradient(0deg, rgba(229,236,239,0) 50%, rgba(229,236,239,1) 100%);
    overflow: hidden;
    padding: 4rem 0;
    h2{
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        span{
            color: $eered;
        }
    }
    .timeline{
        margin-top: 5rem;
        .line{
            width: 100%;
            height: 0.06rem;
            background: linear-gradient(90deg, rgba(229,236,239,0) 0%, rgba(229,236,239,1) 10%, rgba(229,236,239,1) 90%, rgba(229,236,239,0) 100%);
        }
    }
}
@include media-breakpoint-down(md) {
    section{
        padding-bottom: 2rem;
        .timeline{
            margin-top: 2rem;
        }
    }
}
</style>
