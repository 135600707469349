<template>
    <section>
        <tag>
            <h4 slot="title">Testimonials</h4>
        </tag>
        <div class="container">
            <div class="testimonial-wrapper row justify-content-between">
                <div class="col-lg-5 wrapper">
                    <div class="image">
                        <transition name="fade" mode="out-in">
                            <img :key="currentTestimonial" :src="this.testimonials[currentTestimonial].image" alt="">
                        </transition>
                    </div>
                    <transition name="fade" mode="out-in">
                        <img class="event" :key="currentTestimonial" :src="this.testimonials[currentTestimonial].logo" alt="">
                    </transition>
                </div>
                <div class="col-lg-6 testimonial">
                    <transition name="fade" mode="out-in">
                        <p class="quote" :key="currentTestimonial">{{ this.testimonials[currentTestimonial].quote }}</p>
                    </transition>
                    <div class="client">
                        <div class="leading">
                        <transition name="fade" mode="out-in">
                            <img class="avatar" :key="currentTestimonial" :src="this.testimonials[currentTestimonial].client_avatar" alt="">
                        </transition>
                        </div>
                        <div class="trailing">
                            <transition name="fade" mode="out-in">
                                <p class="name" :key="currentTestimonial">{{ this.testimonials[currentTestimonial].client_name }}</p>
                            </transition>
                            <transition name="fade" mode="out-in">
                                <p class="title" :key="currentTestimonial">{{ this.testimonials[currentTestimonial].client_title }}</p>
                            </transition>
                        </div>
                    </div>
                    <div class="tabs">
                        <div :class="[{ active: index == currentTestimonial }, 'tab']" v-for="(tesimonial, index) in testimonials" v-on:click="viewTestimonial(index)">
                            <div class="bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .fade-enter-active, .fade-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity;
    }
    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    section{
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
        background: linear-gradient(0deg, rgba(229,236,239,1) 0%, rgba(255,255,255,1) 100%);
        .section-tag{
            display: none;
        }
        .wrapper{
            position: relative;
            .image{
                position: relative;
                transform: skew(7.5deg);
                overflow: hidden;
                display: flex;
                justify-content: center;
                img{
                    transform: skew(-7.5deg);
                    height: 30rem;
                    width: 125%;
                    object-fit: cover;
                }
            }
            .event{
                position: absolute;
                bottom: 2rem;
                left: -1.5rem;
                height: 6rem;
            }
        }
        .testimonial{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .quote{
                transition: font-size 0.2s, opacity 0.2s;
                margin-bottom: 3rem;
                font-size: 1.5rem;
                color: #192A33;
                flex-grow: 1;
                align-items: center;
                display: flex;
                span{
                    font-weight: 500;
                }
            }
            .client{
                margin-bottom: 2rem;
                display: flex;
                .avatar{
                    width: 4rem;
                    height: 4rem;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 2rem;
                }
                .name{
                    transition: opacity 0.2s;
                    text-transform: uppercase;
                    color: #192A33;
                    font-weight: 700;
                    margin-bottom: 0.25rem;
                }
                .title{
                    transition: font-size 0.2s, opacity 0.2s;
                    color: #727F86;
                    margin-bottom: 0;
                }
            }
            .tabs{
                display: flex;

                .tab{
                    display: flex;
                    align-items: flex-end;
                    margin-right: 1rem;
                    flex-grow: 1;
                    transform: skew(-15deg);
                    height: 1.25rem;
                    cursor: pointer;
                    .bar{
                        transition: background 0.2s;
                        height: 40%;
                        width: 100%;
                        background: #D4D9DE;
                    }
                    &:last-of-type{
                        margin-right: unset;
                    }
                    &:hover, &.active{
                        .bar{
                            background: #B42A34;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1399px) {
        section{
            .testimonial{
                .quote{
                    font-size: 1.3rem;
                }
                .client{
                    .title{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md){
        section{
            padding-top: 6rem;
            .section-tag{
                display: block;
            }
            .testimonial-wrapper{
                flex-direction: column-reverse;
                .wrapper{
                    margin-top: 3rem;
                    .image{
                        height: 25rem;
                        transform: skew(0);
                        img{
                            transform: skew(0);
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .event{
                        left: unset;
                        right: 2rem;
                        bottom: -2rem;
                    }
                }
                .testimonial{
                    .client{
                        .title{
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm){
        section{
            padding-bottom: unset;
            .testimonial-wrapper{
                .wrapper{
                    margin-bottom: 3rem;
                    .image{
                        height: 20rem;
                    }
                    .event{
                        height: 5rem;
                        bottom: -1rem;
                    }
                }
            }
        }
    }
</style>

<script>
import tag from './tag.vue';
export default{
    components: { tag },
    data(){
        return {
            currentTestimonial: 0,
            testimonials: [{}]
        }
    },
    mounted(){
        this.loadTestimonials();
    },
    methods: {
        loadTestimonials:function(){
            axios.get('/api/testimonials').then(res=>{
                if(res.status==200){
                    this.testimonials=res.data;
                }
            }).catch(err=>{
                console.log(err)
            });
        },
        viewTestimonial: function(i){
            this.currentTestimonial = i;
        }
    }
}
</script>
