import { render, staticRenderFns } from "./navigationbar.vue?vue&type=template&id=70ad6bf3&scoped=true"
import script from "./navigationbar.vue?vue&type=script&lang=js"
export * from "./navigationbar.vue?vue&type=script&lang=js"
import style0 from "./navigationbar.vue?vue&type=style&index=0&id=70ad6bf3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ad6bf3",
  null
  
)

export default component.exports