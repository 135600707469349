<template>
<div id="studios">
    <slant-button class="fixed" text="Book A Studio" name="contact" @click.native="trackBooking" />
    <section-header>
        <h1 slot="title">OUR CUTTING EDGE STUDIOS THAT GO <span>BEYOND ESPORTS</span></h1>
        <img slot="bg" src="https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/studios.jpg"></img>
        <slant-button class="first" slot="button" text="Burbank" name="studios" anchor="#burbank" />
        <slant-button slot="button" text="Columbus" name="studios" anchor="#columbus"/>
    </section-header>

    <template v-for="studio in studios">
        <studio-section :studio="studio" />
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="divider"></div>
                </div>
            </div>
        </div>
    </template>

</div>
</template>

<script>
export default{
    data(){
        return {
            studios: [{}]
        }
    },
    mounted(){
        this.loadStudios();
    },
    methods: {
        loadStudios:function(){
            axios.get('/api/studios').then(res=>{
                if(res.status==200){
                    this.studios=res.data;
                }
            });
        },
        trackBooking:function(){
            window.fathom.trackGoal('YW7JTKVJ', 0);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
#studios{
    h1{
        margin-bottom: 2rem;
    }
    .first{
        margin-top: 2rem;
        margin-right: 2rem;
    }
    .fixed{
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 2;
    }
    .divider{
        width: 100%;
        background: $silver;
        height: 0.1rem;
    }
}
@include media-breakpoint-down (md) {
    #studios{
        .fixed{
            bottom: .5rem;
            right: .5rem;
        }
    }
}
</style>
