<template>
    <div id="segment">
        <slot name="logo"></slot>
        <slot name="date"></slot>
        <div class="line"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "./resources/sass/_variables.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~bootstrap/scss/mixins';
#segment{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        height: 3rem;
        margin-bottom: 1rem;
    }
    p{
        text-transform: uppercase;
        color: $stone;
        font-size: 0.8rem;
        font-weight: 700;
    }
    .line{
        height: 1rem;
        width: 0.06rem;
        background: $silver;
    }
}
@include media-breakpoint-down(md) {
    #segment{
        img{
            height: 2rem;
        }
    }
}
@include media-breakpoint-down(sm) {
    #segment{
        img{
            height: 1.5rem;
        }
        p{
            font-size: 0.8rem;
        }
    }
}

</style>
