<template>
    <section>
        <div class="container">
            <div class="partners row justify-content-center">
                <h2>Our Partners</h2>
            </div>
        </div>
        <div class="carousel-wrapper">
            <div class="gradient"></div>
            <carousel :loop="true" :nav="false" :dots="false" :autoplay="true" :items="6" :autoplayTimeout="1000" :responsive="{0:{items:3},576:{items:3},768:{items:3},992:{items:6},1400:{items:6},1401:{items:9}}">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/blizzard.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/100t.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/343.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/andbox.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/ea.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/clg.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/cygames.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/cdl.svg">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/venn.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/epic.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/games-float.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/msg.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/ncsoft.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/pax.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/psyonix.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/riot.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/semc.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/tencent.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/turn10.png">
                <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/partners/wargaming.png">
            </carousel>
        </div>
    </section>
</template>

<script>
import carousel from 'vue-owl-carousel'
export default {
    components: { carousel },
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        background: linear-gradient(0deg, rgba(229,236,239,1) 0%, rgba(255,255,255,1) 20%);
        padding-top: 5rem;
        padding-bottom: 5rem;
        .partners{
            text-align: center;
            margin-bottom: 2rem;
        }
        .carousel-wrapper{
            position: relative;
            .gradient{
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 80%, rgba(255,255,255,1) 100%);
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: 2;
            }
            .owl-stage{
                position: relative;
                z-index: 0;
                img{
                    height: 7rem;
                    object-fit: contain;
                    padding: 1.5rem;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            .partners{
                margin-bottom: 1rem;
            }
            .carousel-wrapper{
                .owl-stage{
                    img{
                        padding: 1rem;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .carousel-wrapper{
            .owl-stage{
                img{
                    padding: unset;
                }
            }
        }
    }
</style>