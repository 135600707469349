<template>
  <div v-if="this.$route.name != 'careers'">
    <div class="container">
        <div class="row justify-content-center wrapper">
            <p class="tagline">Now <span>Recruiting</span></p>
            <slant-button text="Apply Now" name="careers" />
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .wrapper{
        padding: 1rem 0;
        align-items: center;
        text-align: center;
        p.tagline{
            margin-bottom: 0;
            margin-right: 1rem;
            text-transform: uppercase;
        }
        span{
            font-weight: 700;
        }
    }
    @include media-breakpoint-down(md) {
        .wrapper{
            flex-direction: column;
            align-items: center;
            p.tagline{
                margin-right: unset;
                margin-bottom: 1rem;
            }
        }
    }
</style>
