<template>
    <div class="col-xl-4 team-member" data-aos="fade-up" data-aos-duration="360" data-aos-offset="160" data-aos-ease="ease-in-quart">
        <div class="name-title">
            <div class="headshot">
                <img :src="member.avatar" :alt="member.name">
            </div>
            <div>
                <p class="name" v-html="member.name"></p>
                <p class="title" v-html="member.title"></p>
            </div>
        </div>
        <div class="wrapper">
            <p class="bio" v-html="member.bio"></p>
        </div>
    </div>
</template>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .team-member{
            .name-title{
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            p{
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: unset;
                font-size: 1rem;
            }
            p.name{
                font-size: 1.6rem;
                color: $chalkboard;
                line-height: 1.6rem;
                margin-bottom: 0.5rem;
            }
            .headshot{
                position: relative;
                height: 6rem;
                width: 6rem;
                margin-bottom: 0.3rem;
                margin-right: 1rem;
                overflow: hidden;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                clip-path: polygon(100% 0, 0 0, 0 100%, 85% 100%, 100% 70%);
                -webkit-clip-path: polygon(100% 0, 0 0, 0 100%, 85% 100%, 100% 70%);
            }
        }
         p.bio{
            font-size: 0.9rem;
            margin-bottom: 0;
            color: #727F86;
        }
    }
    @media screen and (max-width: 1399px) {
        .team-member{
            margin-bottom: 3rem;
            &:last-child{
                margin-bottom: unset;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        .team-member{
            .name-title{
                h4{
                    font-size: 1.5rem;
                }
                p{
                    font-size: 0.8rem;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .team-member{
            &:last-child{
                margin-bottom: unset;
            }
            .name-title{
                h4{
                    font-size: 1.75rem;
                }
                p{
                    font-size: 1rem;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .team-member{
            .name-title{
                margin-bottom: 1rem;
                flex-direction: column;
                align-items: flex-start;
                .headshot{
                    margin-bottom: 2rem;
                    width: 7rem;
                    height: 7rem;
                    &:before{
                    content: '';
                        bottom: -3rem;
                        right: -6.5rem;
                    }
                }
                h4{
                    margin-bottom: 1rem;
                }
            }
        }
    }
</style>

<script>
    export default{
        props: ['member']
    }
</script>
