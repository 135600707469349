<template>
    <a v-if="external" :href="external" v-html="text"></a>
    <router-link v-else-if="name" :to="{ name: this.name, hash: anchor }">{{ text }}</router-link>
    <button v-else>{{ text }}</button>
</template>

<script>
export default {
    props: ['name', 'external', 'text', 'anchor'],
}
</script>

<style lang="scss" scoped>
    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    a, button{
        transition: background 0.2s;
        padding: 0.6rem 1.4rem 0.6rem 1.2rem;
        background: $eered;
        color: $white;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        -webkit-clip-path: polygon(100% 0, 100% 60%, 92% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 60%, 92% 100%, 0 100%, 0 0);
        &:hover{
            background: lighten($eered, 5%);
        }
    }
</style>
