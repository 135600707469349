<template>
   <div class="col-6 col-sm-4 col-md-3">
        <h4>{{ stat.value }}</h4>
       <p>{{ stat.name }}</p>
   </div>
</template>

<style lang="scss" scoped>

    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    h4{
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 0.4rem;
        line-height: 1.6rem;
    }
    p{
        font-size: 1rem;
        color: #727F86;
    }
</style>

<script>
    export default{
        props: ['stat'],
    }
</script>
