<template>
    <div id="services">
        <section-header>
            <h1 slot="title">Events worthy of <span>fandom</span></h1>
            <img slot="bg" src="https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/stories.jpg"></img>
        </section-header>

        <template v-for="event in portfolio">
            <story :event="event"/>
        </template>

        <events></events>
        <testimonials></testimonials>
    </div>
</template>

<script>
export default{
    data(){
        return {
            portfolio: [{}]
        }
    },
    mounted(){
        this.loadPortfolio();
    },
    methods: {
        loadPortfolio:function(){
            axios.get('/api/portfolio').then(res=>{
                if(res.status==200){
                    this.portfolio=res.data;
                }
            }).catch(err=>{
                console.log(err)
            });
        }
    }
}
</script>
