<template>
    <section>
        <div class="container">
            <div class="wrapper">
                <div class="row justify-content-between">
                    <div class="col-lg-6">
                        <slot name="heading" />
                    </div>
                    <div class="col-2">
                        <slot name="logo" />
                    </div>
                </div>
            </div>
            <div class="row flex-wrap">
                <template v-for="member in team" v-if="member.org_id == org">
                    <member :member="member"/>
                </template>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        padding: 4rem 0;
        overflow: hidden;
        position: relative;
        .wrapper{
            margin-bottom: 2rem;
            h2{
                text-transform: uppercase;
            }
            img{
                width: 12rem;
            }
        }
    }
    @include media-breakpoint-down(lg) {
        section{
            .wrapper{
                h2{
                    padding-right: unset;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            padding-top: 3rem;
            padding-bottom: 3rem;
            .wrapper{
                margin-bottom: 1rem;
                img{
                    display: none;
                }
            }
        }
    }
</style>

<script>
    export default{
        props: ['org'],
        data(){
            return {
                team: []
            }
        },
        mounted(){
            this.loadTeam();
        },
        methods: {
            loadTeam:function(){
                axios.get('/api/team').then(res=>{
                    if(res.status==200){
                        this.team=res.data;
                    }
                }).catch(err=>{
                    console.log(err)
                });
            },
        }
    }
</script>
