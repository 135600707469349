<template>
  <section>
      <img class="background" src="https://d2n34pb7vz7boc.cloudfront.net/images/elements/logo-slice-dark.png" alt="">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-lg-8 title">
                  <h2>Our Production Studios</h2>
                  <p>Cutting edge studios that go beyond esports.</p>
              </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-lg-7 studios">
                <studio class="top">
                    <h3 slot="name">Burbank Studio</h3>
                    <img slot="image" src="https://d2n34pb7vz7boc.cloudfront.net/images/studios/burbank.jpg" alt="">
                </studio>
                <studio>
                    <h3 slot="name">Columbus Studio</h3>
                    <img slot="image" src="https://d2n34pb7vz7boc.cloudfront.net/images/studios/columbus.jpg" alt="">
                </studio>
            </div>
            <div class="col-lg-4 features">
                <div class="row justify-content-between sticky">
                    <template v-for="feature in features">
                        <studio-feature :feature="feature" />
                    </template>
                </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
    export default{
        props: ['features']
    }
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        position: relative;
        padding: 6rem 0;
        background: $chalkboard;
        color: $white;
        img.background{
            position: absolute;
            top: 0;
            width: 100%;
            object-fit: contain;
        }
        .title{
            margin-bottom: 6rem;
            text-align: center;
            h2{
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 2rem;
            }
        }
        .studios{
            .top{
                margin-bottom: 4rem;
            }
        }
        .features{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .sticky{
                position: sticky;
                top: 25vh;
            }
        }
    }
    @include media-breakpoint-down(md){
        section{
            .title{
                margin-bottom: 3rem;
            }
            .studios{
                margin-bottom: 3rem;
            }
            .features{
                .sticky{
                    position: unset;
                }
            }
        }
    }
</style>
