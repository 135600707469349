<template>
    <div class="feature col-xl-4 col-lg-6 col-md-4 col-6">
        <div class="dot"></div>
        <p>{{ feature.feature }}</p>
    </div>
</template>

<style lang="scss" scoped>

    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .feature{
        padding: 1rem;
        margin-bottom: 2rem;
        p{
            font-size: 1rem;
            color: #ffffff
        }
        .dot{
            transform: skew(-15deg);
            width: 0.6rem;
            height: 0.6rem;
            background: #B42A34;
            margin-bottom: 0.6rem;
        }
    }
    @include media-breakpoint-down(lg){
        
    }
    @include media-breakpoint-down(md){
        
    }
</style>

<script>
    export default{
        props: ['feature'],
    }
</script>
