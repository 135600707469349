<template>
    <section>
        <div class="container story">
            <div class="row wrapper">
                <div class="col-lg-8 left">
                    <div class="image">
                        <img :src="event.background"/>
                    </div>
                    <div class="row">
                        <template v-for="stat in event.stats">
                            <stat :stat="stat" />
                        </template>
                    </div>
                </div>
                <div class="col-lg-4 right" data-aos="fade-left" data-aos-offset="150" data-aos-duration="400" data-aos-ease="ease-in-quart">
                    <div class="details">
                        <img :src="event.logo"/>
                        <h4 v-html="event.name"></h4>
                        <p v-html="event.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .story{
        padding-bottom: 3rem;
        padding-top: 3rem;
        border-bottom: 2px solid #E5ECEF;
        h4{
            text-transform: uppercase;
            margin-bottom: 3rem;
        }
        p{
            color: $stone;
        }
        .left{
            .image{
                position: relative;
                margin-bottom: 3rem;
                overflow: hidden;
                &:before{
                    content: '';
                    position: absolute;
                    width: 6rem;
                    height: 8rem;
                    background: $white;
                    bottom: -2rem;
                    right: -4rem;
                    z-index: 1;
                    transform: rotate(30deg);

                }
                img{
                    width: 100%;
                    height: 25rem;
                    object-fit: cover;
                }
            }
        }
        .right{
            .details{
                padding-left: 3rem;
            }
            img{
                margin-bottom: 3rem;
                max-height: 6rem;
            }
        }
    }
    @include media-breakpoint-down(md){
        .story{
            .wrapper{
                flex-direction: column-reverse;
                .left{
                    img{
                        height: 18rem;
                    }
                }
                .right{
                    margin-bottom: 2rem;
                    .details{
                        padding-left: 0;
                    }
                    img{
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .story{
            .wrapper{
                .left{
                    .image{
                        &::before{
                            right: -5rem;
                        }
                        img{
                            height: 12rem;
                        }
                    }
                }
                .right{
                    h4{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
</style>

<script>
    export default{
        props: ['stats','event'],
    }
</script>
