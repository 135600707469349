/* Bootstrap */
require('./bootstrap');
/* Inital Setup */
window.Vue = require('vue').default;
import Vue from 'vue'
import VueTyper from 'vue-typer'
/* Vue Router */
import Router from 'vue-router'
/* GSAP */
import gsap from "gsap";
/* AOS */
import AOS from 'aos'
import 'aos/dist/aos.css'
/* Lightbox */
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
/* Sentry */
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(CoolLightBox)
Vue.use(VueTyper)
Vue.use(Router)

/* Pages */
import index from './pages/Index.vue';
import about from './pages/About.vue';
import studios from './pages/Studios.vue';
import services from './pages/Services.vue';
import work from './pages/Work.vue';
import careers from './pages/Careers.vue';
import careers2 from './pages/Careers2.vue';
import contact from './pages/Contact.vue';

// Global
Vue.component('nav-bar', require('./components/global/navigationbar.vue').default);
Vue.component('footer-global', require('./components/global/footer.vue').default);
Vue.component('section-header', require('./components/global/header.vue').default);
Vue.component('slant-button', require('./components/global/button.vue').default);
Vue.component('testimonials', require('./components/global/testimonials.vue').default);
Vue.component('studio', require('./components/global/studio.vue').default);
Vue.component('apply', require('./components/global/apply.vue').default);
Vue.component('tag', require('./components/global/tag.vue').default);
Vue.component('studios', require('./components/global/studios-cta.vue').default);

// Home
Vue.component('home-service', require('./components/home/homeServices.vue').default);
Vue.component('work-cta', require('./components/home/workCTA.vue').default);
Vue.component('hero', require('./components/home/hero.vue').default);

// About
Vue.component('team', require('./components/about/team.vue').default);
Vue.component('member', require('./components/about/teamMember.vue').default);
Vue.component('partners', require('./components/about/partners.vue').default);
Vue.component('timeline', require('./components/about/timeline.vue').default);
Vue.component('segment', require('./components/about/segment.vue').default);
Vue.component('info', require('./components/about/info.vue').default);

// Studios
Vue.component('studio-section', require('./components/studios/studiosSection.vue').default);
Vue.component('core-capability', require('./components/studios/coreCapability.vue').default);
Vue.component('studio-capability', require('./components/studios/studioCapability.vue').default);

// Services
Vue.component('service', require('./components/services/serviceBlock.vue').default);
Vue.component('service-capability', require('./components/services/serviceCapability.vue').default);
Vue.component('services-studio', require('./components/services/serviceStudios.vue').default);
Vue.component('studio-feature', require('./components/services/studioFeature.vue').default);

//Stories
Vue.component('story', require('./components/stories/storyBlock.vue').default);
Vue.component('stat', require('./components/stories/storyStat.vue').default);
Vue.component('storyTestimonial', require('./components/stories/storyTestimonial.vue').default);
Vue.component('events', require('./components/stories/events.vue').default);

//Contact
Vue.component('contact', require('./components/contact/contact.vue').default);

const routes = [
    { path: '/', name: 'index', component: index },
    { path: '/about', name: 'about', component: about },
    { path: '/studios', name: 'studios', component: studios },
    { path: '/services', name: 'services', component: services },
    { path: '/work', name: 'work', component: work },
    { path: '/careers', name: 'careers', component: careers2 },
    { path: '/contact', name: 'contact', component: contact },
    { path: '*', redirect: { name: 'index' }}
];
const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if(to.hash){
            return{
                selector: to.hash,
                offset: { x: 0, y: 57 },
                behavior: 'smooth'
            }
        }else{
            return { x: 0, y: 0 }
        }
    },
    routes
})

/* Sentry */
Sentry.init({
    Vue,
    dsn: "https://3ccbbb05def949929eca0ba6a37ce866@o502620.ingest.sentry.io/4504244883292160",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "esports-engine.test", "esportsengine.gg", /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
});

const app = new Vue({
    created () {
        AOS.init()
    },
    router,
    el: '#app',
});
