<template>
    <header>
        <div class="container">
            <div class="col-12">
                <div class="wrapper">
                    <div class="logo">
                        <router-link to="/">
                            <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/esports-engine.svg" alt="">
                        </router-link>
                    </div>
                    <i class="menu burger fal fa-bars" v-on:click="toggleMobile"></i>
                    <nav v-bind:class="{active:isActive}">
                        <ul>
                            <router-link to="/" v-on:click.native="closeMobile"><li>Home</li></router-link>
                            <router-link to="/about" v-on:click.native="closeMobile"><li>About</li></router-link>
                            <router-link to="/studios" v-on:click.native="closeMobile"><li>Studios</li></router-link>
                            <router-link to="/services" v-on:click.native="closeMobile"><li>Services</li></router-link>
                            <router-link to="/work" v-on:click.native="closeMobile"><li>Our Work</li></router-link>
                            <router-link to="/careers" v-on:click.native="closeMobile"><li>Careers</li></router-link>
                            <router-link to="/contact" v-on:click.native="closeMobile"><li>Contact Us</li></router-link>
                            <div class="socials">
                                <a href="https://www.facebook.com/EsportsEng"><i class="fab fa-facebook"></i></a>
                                <a href="https://twitter.com/EsportsEng"><i class="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/esportseng/"><i class="fab fa-instagram"></i></a>
                                <a href="https://www.linkedin.com/company/esports-engine"><i class="fab fa-linkedin"></i></a>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .fade-enter-active, .fade-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity;
    }
    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    header{
        background: linear-gradient(-90deg, rgba(13,28,36,0.7021183473389356) 0%, rgba(13,28,36,1) 100%);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        backdrop-filter: blur(8px);
        .wrapper{
            display: flex;
            justify-content: space-between;
            .logo{
                background: rgba(25, 42, 51, .95);
                display: flex;
                align-items: center;
                &:before{
                    z-index: -1;
                    position: absolute;
                    content: '';
                    right: calc(100% - 1rem);
                    height: 100%;
                    width: 100%;
                    background: rgba(25, 42, 51, .95);
                }
                &:after{
                    content: '';
                    background: rgba(25, 42, 51, .95);
                    height: 100%;
                    width: 1rem;
                    transform: skew(-15deg)translateX(0.5rem);
                }
            }
            i.menu{
                font-size: 1.5rem;
                font-weight: 500;
                padding: 1rem;
                color: white;
                transform: skew(-5deg);
                touch-action: manipulation;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
            }
            nav{
                display: flex;
                align-items: center;
                &.active{
                    display: flex;
                    position: fixed;
                    padding: 1rem;
                    width: 100%;
                    height: 100vh;
                    background: rgba(25, 42, 51, 0.95);
                    backdrop-filter: blur(8px);
                    -webkit-backdrop-filter: blur(8px);
                    z-index: -1;
                    left: 0;
                    top: 0;
                    justify-content: center;
                    align-items: center;
                    ul{
                        flex-direction: column;
                        align-items: center;
                        padding: 0;
                        a{
                            color: $stone;
                            li{
                                padding: 0.5rem;
                                text-align: center;
                                font-size: 1.5rem;
                                &:hover::after{
                                    display: none;
                                }
                                &.router-link-exact-active{
                                    color: $white;
                                }
                                &.router-link-active{
                                    color: $white;
                                }
                            }
                            &.router-link-exact-active::after{
                                display: none;
                            }
                        }
                    }
                }
                ul{
                    display: flex;
                    list-style: none;
                    margin-bottom: 0;
                    a{
                        position: relative;
                        color: $stone;
                        li{
                            position: relative;
                            text-decoration: none;
                            padding: 1rem;
                            font-size: 1rem;
                            font-weight: 700;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            &:after{
                                transition: opacity 0.2s;
                                opacity: 0;
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: linear-gradient(0deg, rgba(25,42,51,1) 0%, rgba(25,42,51,0) 100%);
                                z-index: -1;
                                transform: skew(-15deg);
                            }
                            &:hover{
                                &:after{
                                    opacity: 1;
                                }
                            }
                        }
                        &.router-link-exact-active{
                            color: $white;
                            &:after{
                                content: '';
                                position: absolute;
                                width: 50%;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                border-bottom: solid 2px #B42A34;
                                z-index: -1;
                            }
                            li{
                                &:after{
                                    opacity: 1;
                                }
                            }
                        }
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    .socials{
                        position: absolute;
                        bottom: 5rem;
                        a{
                            padding: 1rem;
                            color: $white;
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg){
        header .wrapper{
            i{
                display: none;
            }
        }
    }
    @include media-breakpoint-down(md){
        header .wrapper{
            .logo{
                img{
                    height: 1.75rem;
                }
            }
            nav{
                display: none;
                ul{
                    a{
                        li{
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>
export default {
    data(){
        return {
            isActive: false
        }
    },
    methods: {
        toggleMobile: function() {
            this.isActive = !this.isActive;
        },
        closeMobile: function() {
            setTimeout(() => this.isActive = false, 0);
        }
    }
}
</script>
