<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="wrapper">
                        <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/esports-engine.svg" alt="">
                        <div class="copyright">
                            <p>Esports Engine © 2021</p>
                            <a href="https://vindex.gg">
                                <p>A Vindex Company</p>
                            </a>
                        </div>
                        <div class="socials">
                            <ul>
                                <div class="links">
                                    <li>
                                    <a href="https://twitter.com/EsportsEng"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/esportseng/"><i class="fab fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/EsportsEng"><i class="fab fa-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/esports-engine"><i class="fab fa-linkedin"></i></a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    footer{
        overflow: hidden;
        position: relative;
        background: #0D1C24;
        padding: 2rem 0rem;
        .wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                width: 8rem;
            }
            .socials{
                padding-left: 7.5px;
                padding-right: 7.5px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .links{
                    display: flex;
                    margin-right: 1rem;
                }
                ul{
                    display: flex;
                    padding: 0;
                    margin-bottom: 0;
                    li{
                        margin-right: 1rem;
                        list-style: none;
                        &:last-of-type{
                            margin-right: unset;
                        }
                        a{
                            transition: color 0.2s;
                            color: #ffffff;
                            i{
                                transition: color 0.2s;
                                font-size: 1.2rem;
                                color: #ffffff;
                            }
                            &:hover{
                                color: #B42A34;
                                i{
                                    color: #B42A34;
                                }
                            }
                        }
                    }
                }
            }
            .copyright{
                display: flex;
                a{
                    text-decoration: none;
                    p{
                        transition: all 0.2s;
                        &:hover{
                            color: #83939B;
                        }
                    }
                }
                p{
                    font-size: 0.8rem;
                    margin-right: 1rem;
                    margin-bottom: unset;
                    color: $stone;
                    &:last-child{
                        margin-right: unset;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        footer{
            .wrapper{
                flex-direction: column;
                img{
                    margin-bottom: 2rem;
                }
                .copyright{
                    margin-bottom: 2rem;
                }
            }
        }
    }
</style>
