<template>
  <section>
      <tag>
          <h4 slot="title">And More...</h4>
      </tag>
      <div class="background">
            <img src="https://d2n34pb7vz7boc.cloudfront.net/images/elements/logo-slice-dark.png" alt="">
      </div>
      <div class="container">
          <div class="row">
                <template v-for="event in events">
                    <div class="col-6 col-md-3 col-xs-6">
                        <img :src="event.logo" />
                    </div>
                </template>
          </div>
      </div>
  </section>
</template>

<script>
export default{
    data(){
        return {
            events: [{}]
        }
    },
    mounted(){
        this.loadEvents();
    },
    methods: {
        loadEvents:function(){
            axios.get('/api/events').then(res=>{
                if(res.status==200){
                    this.events=res.data;
                }
            }).catch(err=>{
                console.log(err)
            });
        }
    }
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        overflow: hidden;
        padding-top: 10rem;
        position: relative;
        background: #192A33;
    }
    .background{
        position: absolute;
        top: 0;
        height: 100%;
    }
    .col-md-3{
        margin-bottom: 8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 5rem;
        }
    }

</style>
