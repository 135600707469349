<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-xl-5 service" data-aos="fade-up">
                    <div class="service-text">
                        <div class="service-title">
                            <slot name="number"></slot>
                            <slot name="service"></slot>
                        </div>
                        <slot name="desc"></slot>
                        <div class="capabilities row">
                            <template v-for="capability in capabilities">
                                <service-capability :capability="capability" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-xl-7 image">
                    <slot name="bg"></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    section{
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 3rem;
        background: linear-gradient(0deg, rgba(229,236,239,1) 0%, rgba(255,255,255,1) 100%);
        .service{
            display: flex;
            align-items: center;
            .service-text{
                display: flex;
                flex-direction: column;
                p{
                    margin-bottom: 2.5rem;
                }
                .capabilities{
                    flex-wrap: wrap;
                }
                .service-title{
                    display: flex;
                    align-items: baseline;
                    h2{
                        color: $eered;
                        text-transform: uppercase;
                        margin-bottom: 2.5rem;
                        &:before{
                            content: attr(data-service);
                            position: absolute;
                            color: $stone;
                            font-size: 1.8rem;
                            margin-left: -3rem;
                        }
                    }
                }
            }
        }
        .image{
            position: relative;
            top: 3rem;
            img{
                height: 100%;
                width: 100%;
                -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 12% 0);
                clip-path: polygon(100% 0, 100% 100%, 0 100%, 12% 0);
                object-fit: cover;
            }
        }
    }
    @media screen and (max-width: 1399px) {
        section{
            .image{
                img{
                    height: 24rem;
                    -webkit-clip-path: unset;
                    clip-path: unset;
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        section{
            height: unset;
            .image{
                top: 3rem;                
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            .service{
                margin-bottom: 1.5rem;
            }
            .image{
                top: unset;
                img{
                    -webkit-clip-path: none;
                    clip-path: none;
                    height: 20rem;
                    object-fit: cover;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        section{
            .image{
                img{
                    height: 16rem;
                }
            }
        }
    }
</style>

<script>
import serviceCapability from './serviceCapability.vue'
    export default{
  components: { serviceCapability },
        props: ['capabilities']
    }
</script>
