<template>
    <section>
        <div class="background">
            <div class="gradient"></div>
            <img class="background-image" src="https://d2n34pb7vz7boc.cloudfront.net/images/backgrounds/contact.jpg" alt="Group huddle at Esports Engine">
       </div>
       <div class="container">
           <div class="row justify-content-between">
               <div class="col-lg-5 left">
                    <h1>Let's Build</h1>
                    <p>We’re excited to build the best experiences in esports. No challenge has ever been too bold for Esports Engine, so don’t hesitate to reach out.</p>
                    <div class="details d-flex">
                        <p>contact@ee.gg</p>
                    </div>
               </div>
               <div class="col-lg-6 right">
                    <transition name="fade" mode="out-in">
                        <form @submit.prevent="submit" v-if="!success">
                            <div class="row title">
                                <div class="col-12">
                                    <p>What are we building?</p>
                                </div>
                            </div>
                            <div class="row inputs">
                                <div class="col-md-6">
                                    <input type="text" placeholder="Name" v-model="fields.name" />
                                    <small v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</small>
                                </div>
                                <div class="col-md-6">
                                    <input type="email" placeholder="Email address" v-model="fields.email" />
                                    <small v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</small>
                                </div>
                                <div class="col-12 subject">
                                <input type="text" placeholder="Subject" v-model="fields.subject" />
                                <small v-if="errors && errors.subject" class="text-danger">{{ errors.subject[0] }}</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <textarea placeholder="Enter your project details here..." cols="30" rows="6" v-model="fields.messagebody"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <small v-if="errors && errors.messagebody" class="text-danger">{{ errors.messagebody[0] }}</small>
                                </div>
                                <div class="col-6 submit">
                                    <button type="submit">Send Message<i class="fal fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </form>
                        <div class="success" v-else>
                            <img src="https://d2n34pb7vz7boc.cloudfront.net/images/logos/esports-engine.svg" alt="Esports Engine">
                            <h4>Thank you for your message. We will be in touch soon.</h4>
                            <p>For now, connect with us on our socials.</p>
                            <div class="socials">
                            <ul>
                                <div class="links">
                                    <li>
                                        <a href="https://twitter.com/EsportsEng"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/esportseng/"><i class="fab fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/EsportsEng"><i class="fab fa-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/esports-engine"><i class="fab fa-linkedin"></i></a>
                                    </li>
                                </div>
                            </ul>
                            </div>
                        </div>
                    </transition>
               </div>
           </div>
       </div>
    </section>
</template>

<script>
export default{
    data(){
        return{
            fields: {},
            errors: {},
            success: false,
            loaded: true,
        }
    },
    methods:{
        submit(){
            if(this.loaded){
                this.loaded = false;
                this.success = false;
                this.errors = {};
                axios.post('/contact', this.fields).then(response => {
                    this.fields = {};
                    this.loaded = true;
                    this.success = true;
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            }
        },
    },
}
</script>

<style lang="scss" scoped>

    @import "./resources/sass/_variables.scss";
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import '~bootstrap/scss/mixins';

    .fade-enter-active, .fade-leave-active {
        transition-duration: 0.2s;
        transition-property: opacity;
    }
    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    section{
        height: 95vh;
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        background: #000;
        overflow: hidden;
        .background{
            height: 100%;
            position: absolute;
            opacity: 0.2;
            .gradient{
                display: none;
            }
            .background-image{
                height: 100%;
                object-fit: cover;
            }
        }
        .left{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 30rem;
            h1{
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        .right{
            form{
                font-size: 1rem;
                .title{
                    margin-bottom: 2rem;
                    p{
                        font-size: 2rem;
                    }
                }
                .inputs{
                    margin-bottom: 3rem;
                    input{
                        padding-top: 0.5rem;
                        padding-bottom: 0.5rem;
                        width: 100%;
                        background: unset;
                        border: unset;
                        border-bottom: solid 1px #727F86;
                        color: #ffffff;
                        caret-color: #B42A34;
                        &::placeholder{
                            color: #727F86;
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                    .subject{
                        margin-top: 3rem;
                    }
                }
                textarea{
                    width: 100%;
                    background: unset;
                    border: unset;
                    border-bottom: solid 1px #727F86;
                    color: #ffffff;
                    caret-color: #B42A34;
                    &::placeholder{
                        color: #727F86;
                    }
                    &:focus{
                        outline: none;
                    }
                }
                .submit{
                    display: flex;
                    justify-content: flex-end;
                    margin-top: .5rem;
                    button{
                        transition: background 0.2s;
                        text-transform: uppercase;
                        font-weight: 700;
                        color: $white;
                        padding-left: 1rem;
                        position: relative;
                        overflow: hidden;
                        background: $eered;
                        border: none;
                        :after{
                            position: absolute;
                            content: '';
                            right: -2rem;
                            bottom: 0;
                            width: 1rem;
                            height: 6rem;
                            background: #000;
                            transform: skew(-30deg);
                        }
                        i{
                            font-size: 1rem;
                            padding: .85rem;
                            color: #ffffff;
                        }
                        &:hover{
                            background: lighten($eered, 5%);
                        }
                    }
                }
            }
            .success{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                img{
                    height: 2rem;
                    margin-bottom: 2rem;
                }
                h4{
                    margin-bottom: 2rem;
                }
                .socials{
                    ul{
                        display: flex;
                        padding: 0;
                        margin-bottom: 0;
                        .links{
                            display: flex;
                            li{
                                margin-right: 1rem;
                                list-style: none;
                                &:last-of-type{
                                    margin-right: unset;
                                }
                                a{
                                    transition: color 0.2s;
                                    color: #ffffff;
                                    i{
                                        transition: color 0.2s;
                                        font-size: 1.2rem;
                                        color: #ffffff;
                                    }
                                    &:hover{
                                        color: #B42A34;
                                        i{
                                            color: #B42A34;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        section{
            height: unset;
            padding-top: 8rem;
            padding-bottom: 8rem;
            .background{
                height: unset;
                width: 125%;
                top: 0;
                .gradient{
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 40%);
                }
                img{
                    height: unset;
                    width: 100%;
                }
            }
            .left{
                height: unset;
                margin-bottom: 4rem;
                h1{
                    margin-bottom: 2rem;
                    p{
                        margin-bottom: 2rem;
                    }
                }
            }
            .right{
                form{
                    .title{
                        margin-bottom: 1rem;
                    }
                    .inputs{
                        margin-bottom: unset;
                        input{
                            margin-bottom: 3rem;
                        }
                        .subject{
                            margin-top: unset;
                        }
                    }
                }
            }
        }
    }
</style>
